<template>
  <div class="workflow-container">
    <div class="local-loader-container" v-if="loading">
      <div class="inava-loader"></div>
    </div>
    <ExceptionHandlingTableGrid
      :columns="columns"
      :data-items="dataItems"
      @data-updated="refreshData"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ExceptionHandlingTableGrid from './ExceptionHandlingGrid.vue'

export default {
  name: 'ExceptionHandlingView',
  components: {
    ExceptionHandlingTableGrid
  },
  data() {
    return {
      loading: false,
      dataItems: [],
      columns: [
        {
          field: 'sgaId',
          title: 'SGA ID',
          width: '175px',
          columnMenu: true,
          cell: 'viewEntityTemplate'
        },
        {
          field: 'requestId',
          title: 'Request ID',
          width: '140px',
          columnMenu: true
        },
        {
          field: 'legalEntityName',
          title: 'Legal Entity Name',
          width: '420px',
          resizable: true,
          columnMenu: true,
          className: 'text-center',
          cell: 'editTemplate'
        },
        {
          field: 'countryOfDomicile',
          title: 'Country Of Domicile',
          width: '230px',
          resizable: true,
          columnMenu: true,
          className: 'text-center',
          filter: 'boolean'
        },
        
        {
          field: 'countryOfRegistry',
          title: 'Country Of Registry',
          width: '220px',
          resizable: true,
          columnMenu: true
        },
        {
          field: 'ultimateParentRequestId',
          title: 'Ultimate Parent Request Id',
          width: '250px',
          resizable: true,
          columnMenu: true
        },
        {
          field: 'exceptionType',
          title: 'Exception Type',
          width: '400px',
          cell: 'exceptionTypeTemplate',
          sortable: false,
          columnMenu: false
        }
      ]
    }
  },
  computed: {
    ...mapState('exceptionHandling', ['exceptionHandlingList'])
  },
  watch: {
    exceptionHandlingList: {
      handler() {
        this.dataItems = this.exceptionHandlingList
      },
      immediate: true
    }
  },
  async mounted() {
    try {
      this.loading = true
      await this.getExceptionHandling()
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions('exceptionHandling', ['getExceptionHandling']),
    async refreshData() {
      try {
        this.loading = true
        await this.getExceptionHandling()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.workflow-container {
  font-size: 20px;
}
.local-loader-container {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary);
  background: rgba(0, 0, 0, 0.1);
  z-index: 9;
  .inava-loader {
    position: relative;
    display: flex;
    margin: auto;
    border-radius: 50%;
    border: none;
    z-index: 900;
    width: 70px;
    height: 70px;
    background-image: '@/assets/Inava-logo-GIF.gif';
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>
